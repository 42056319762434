.exchange-type {
    margin-top: 4rem;
    gap: 10px;
}
.bg-tab-bet{
    background-color: #30469C;
}

.brdr {
    border: 1px solid #ddd;
}

.exchange-data-tabs{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #30469C;
}
.exchange-tab_ {
    background: #30469C !important;
    color: #fff !important;
    width: 23% !important;
    height: 100%;
    font-family: var(--font-family) !important;
    font-size: 14px !important;
    padding: 0px 0 !important;
    position: relative;
}
#active::after{
    content: "";
    width: 100%;
    height: 2.5px;
    background-color: #ffffff;
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: 1200px) {
    .exchange-type {
        margin-top: 7rem;
        margin-bottom: 4rem;
        gap: 10px;
    }
}

.outer-cont {
    display: flex;
    padding: 10px;
    gap: 15px;
}

.outer-bg {
    background: #333333;
    margin-top: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.play-spn {
    color: #fff;
}

.spn-play {
    font-size: 13px;
}

.svg-btn {
    margin-top: 10px;
    width: 35px;
    height: 28px;
}

.svg-info {
    float: right;
}

