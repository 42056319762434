:root{
    --session-run-yes-box-bg:#E85D4A;
    --session-runs-no-box-bg:#0F36AC;
    --run-session-run-color:#ffffff;
    --run-session-over-color:#ffffff;
}

.fancy-all-bets-sessions{
    margin-top: 20px;
}

.session-run-card{
    width: 100%;
    margin: auto;
    margin-top: 0px;
    background-color: #fff;
    margin-top: 15px;
}
.session-run-card-header{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.session-run-card-header-content{
    width: 55%;
}

.session-runcard-label{
    font-family: var(--font-family);
    font-size:15px;
    font-weight: 600;
}


.session-run-back-lay-labels{
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



/* Comman part in run odds and bookmarker card -------------- */
.back-label{
    width: 45%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 650;
    text-transform: capitalize;
}
.lay-label{
    width: 45%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 650;
    text-transform: capitalize;
}
/* End of comman part ----------------------------- */
/* start the bookmarker runes row ------------------ */
.session-runes-row{
    width: 100%;
    margin: auto;
    margin-top: 1px;
    margin-top: 0px;
    border: 1px solid var(--scorcard-bets-row-border);
    border-left: 0px;
    border-right: 0px;
    box-sizing: border-box;
}
.session-runes-row-wrapper{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* end of bookmarker runes row ---------------- */



.session-run-task-name{
    width:60%;
    font-family: var(--font-family);
    color: var(--scorcard-bets-title-color);
    font-size: 12px;
    font-weight: 550;
    position: relative;
    padding-left: 0px;
    display: flex;
    align-items: center;
}
.starIcon{
    width: 15px;
    height: 15px;
    margin-right: 6px;
}



.session-run-back-lay-boxes{
    width: 40%;
    display: flex;
    justify-content: space-between;
}
.session-run-back-box{
    width: 48%;
    height: 55px;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
    background-color: var(--session-run-yes-box-bg);
}
.run-session-run{
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--run-session-run-color);
    font-weight: 600;
    line-height: 15px;
}
.run-session-over{
    font-family: var(--font-family);
    font-size:11px;
    color: var(--run-session-over-color);
    margin-top: 3px;
    font-weight: 600;
    line-height: 15px;
}


.session-run-lay-box{
    width: 48%;
    height: 55px;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
    background-color: var(--session-runs-no-box-bg);
}
















  