.most-played-section {
    margin-top: 20px;
}

.most-played-swipper-container {
    margin-top: 15px;
}

.most-played-game-image {
    width: 95%;
    height: 131px;
    cursor: pointer;
    border-radius: 7px;
}

.most-played-game-lobby-row {
    flex-direction: row;
    display: flex;
    margin-top: 10px !;
}
@media (min-width:1310) and (max-width: 1650px) {
    .most-played-game-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        border-radius: 7px;
    }

    .most-played-game-lobby-row {
        margin: 0px;
    }

}

@media (max-width: 1300px) {
    .most-played-game-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        border-radius: 7px;
    }

    .most-played-game-lobby-row {
        margin: 0px;
    }

}

@media (max-width: 1050px) {
    .most-played-game-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 7px;
    }

    .most-played-game-lobby-row {
        margin: 5px;
    }

}

@media (max-width: 1000px) {
    .most-played-game-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 7px;
    }

    .most-played-game-lobby-row {
        margin: 5px;
    }

}

@media (max-width: 830px) {
    .most-played-game-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 7px;
    }

    .most-played-game-lobby-row {
        margin: 5px;
    }

}

@media (max-width: 780px) {
    .most-played-game-image {
        width: 95%;
        height: auto;
        margin-inline-end: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 7px;
    }

    .most-played-game-lobby-row {
        margin: 5px;
    }

    .most-played-swipper-container {
        margin-top: 10px;
    }


}


@media (max-width: 600px) {
    .most-played-game-image {
        width: 95%;
        height: auto;
        margin-inline-end: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 7px;
    }

    #game-swiper {
        margin-bottom: 15px;
    }

    .swiper-container {
        margin: 0px;
    }

    .most-played-swipper-container {
        margin-top: 10px;
    }

}

@media (max-width: 365px) {
    .most-played-game-image {
        width: 95%;
        height: auto;
        margin-inline-end: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 7px;
    }

}


@media (max-width: 290px) {
    .most-played-game-image {
        width: 95%;
        height: auto;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
        border-radius: 7px;
    }

    .most-played-swipper-container {
        margin-top: 10px;
    }


}