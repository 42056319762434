form.log {
  margin: 0px auto;
}

.clsbtn {
  padding: 10px 8px !important;
  background: #01143c;
}

.withd {
  color: #fff;
  font-family: "Roboto";
  font-size: 15px;
}
.payMain {
  background-color: #fff;
}

.btn-close {
  background-color: white !important;
  margin-right: 2px !important;
  opacity: 0.9 !important;
  width: 6px !important;
  height: 6px !important;
}

.signbt-withdraw {
  padding: 3px 50px !important;
}

.modal-withdraw {
  padding: 0;
}
.point-withdraw {
  color: red;
  margin-bottom: 0px;
  font-size: 11px;
  line-height: 20px;
  text-align: justify;
}
