.match-score-row{
    margin-top: 0px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding-top: 5px;
    padding-bottom: 5px;
}
.match-score-row-container{
    width: 97%;
    margin: auto;
}
.match-score-heading{
    font-size: 12px;
    font-weight: 600;
    color: #333333;
}

.score-box-container{
   display: flex;
   align-items: center;
   margin-top: 5px;
}
.match-score-box{
    width: 19px;
    height: 19px;
    clip-path: circle();
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: #000000;
    font-weight: 600;
    margin-right: 10px;
}
#dot-ball-score{
    background-color: #D9D9D9;
}
#run-ball-score{
    background-color: #D6DF3C;
}

/* match total score section desinging  */
.live-match-total-score{
    width:100%;
    margin: auto;
    display: flex;
    border-top: 1px solid #D9D9D9;
    padding-top: 3px;
    padding-bottom: 3px;
    align-items: center;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.live-match-totla-score-container{
    width: 97%;
    margin: auto;
    display: flex;
    align-items: center;
}
.live-total-over{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
}
.saprator{
    width: 1px;
    height: 18px;
    background-color: #333333;
    margin-left: 7px;
    margin-right: 7px;
}
.live-total-runs{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
}
.live-runs-pr-wicket{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
}