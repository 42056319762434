
:root {
    --exchange-data-back-box-bg: #0F36AC;
    --exchange-data-lay-box-bg: #E85D4A;
    --exchange-data-bet-amount-color: #fff;
    --exchange-data-bet-value-color: #fff;
}


.exchange-data-card{
    margin-top: 20px;
}
.exchange-data-card-header {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.exchange-data-card-content {
    width: 60%;
}

.starIcon {
    width: 15px;
    height: 15px;
}

.exchange-data-card-label {
    display: flex;
    align-items: center;
}

.exchange-data-title {
    max-width: 90%;
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 600;
    margin-left: 3px;
  
}

.exchange-data-cashout-btn {
    width: 80px;
    height: 27px;
    background-color: var(--exchange-data-back-box-bg);
    margin-left: 0px;
    border-radius: 30px;
    color: #fff;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-left: 6px;
}

.exchange-data-card-back-lay-labels {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Comman part in match odds and bookmarker card -------------- */
.exchange-data-back-label {
    width: 48%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 650;
    text-transform: capitalize;
}

.exchange-data-lay-label {
    width: 48%;
    text-align: center;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 650;
    text-transform: capitalize;
}
