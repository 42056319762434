.financial-details {
   
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
}

.name-header {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px 15px;
    display: flex;
    justify-content: center;
    /* padding: 24px 0px 19px; */
}

.horizontal-line {
    border: none;
    border-top: 1px solid hsl(0, 2%, 46%);
    margin: 12px 0; /* Adjust margin to leave space at both ends */
}

.balance-info-heading {
    font-size: 16px; 
    font-weight: 600;
    margin-bottom: 0px;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    margin-bottom: 4px;
    margin-left: 4px;
}

.label {
    font-weight: 400;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

.value {
    font-size: 14px;
    font-weight: 500;
}

.account-statement, .change-password {
    cursor: pointer;
    font-size: 16px; 
    font-weight: 600;
    margin-bottom: 0px;
}

.red {
    color: red;
  }
  
  .green {
    color: green;
  }

  