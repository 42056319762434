.StatementOuter {
    width: 100%;
  }
  .statementTop {
    width: 100%;
    background-color: #242424;
    border-bottom: 4px solid #476bc8;
  }
  .statopshn {
    color: #fff;
    text-align: center;
    margin-bottom: 0;
    padding: 5px 0;
  }
  .statopshn span {
    font-size: 12px;
    margin: 2px 5px;
  }
  .searchTp {
    background-color: #242424;
    margin-top: 80px;
  }
  .clrBoth {
    clear: both;
  }
  .row-mrs {
    padding: 5px 5px;
    padding-bottom: 10px;
    margin: 0;
    margin-left: 20px;
  }
  .input-outer .cntrl {
    width: 100%;
    background-color: #fff;
    border: 0;
    padding: 2px 3px;
    border-radius: 8px;
    font-size: 14px;
  }
  .input-outer {
    margin-top: 3px;
  }
  .row-mrs .col-4 {
    padding: 0 5px;
  }
  .searchBTn {
    background: linear-gradient(to right, #436ad8 0%, #b264b9 100%);
    border-radius: 12px;
    padding: 3px 15px;
    margin-top: 25px;
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
  }
  .searchBTn:hover {
    color: #fff;
  }
  .stmnt {
    border-bottom: 2px solid #a672dd;
    display: inline-block;
    font-size: 14px;
  }
  .tableOuter {
    padding: 12px 12px;
    background-color: #edebec;
    height: 100%;
    height: 100vh;
  }
  .frDate {
    font-size: 12px;
    color: #898989;
    margin: 0;
    padding: 0;
  }
  .tablinner {
    background-color: #fff;
    padding: 5px;
    border: 1px solid #845db0;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  .stat-mnt {
    border-collapse: collapse;
  }
  .stat-mnt tr th {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 0px;
    vertical-align: top;
    border: 0px !important;
  }
  .stat-mnt tr td {
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    padding-left: 7px;
    vertical-align: top;
    border: 0px !important;
  }
  .lss {
    color: #ae4b4c;
  }
  .prfts {
    color: #12b928;
  }
  .content-with {
    padding: 0 !important;
    height: 110px;
    font-family: "Poppins", sans-serif;
  }
  .withreq-card{
    margin: 0 16px;
  }
  
  @media (min-width: 1200px) {
    .StatementOuter {
      width: 50%;
      margin: 0 380px;
    }
    .popup-content3 {
      background-color: #fff;
      border-radius: 4px;
      max-width: 95%;
      width: 400px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      height: 13.5rem;
      position: fixed;
      top: 50px;
      right: 0;
    }
  }
  
  @media screen and (max-width: 290px) {
    .input-outer .cntrl {
      font-size: 14px;
      font-size: 11px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  