


#comman-swiper-pagination .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #31469C;
    -webkit-appearance: none;
    border-radius: 0;
    margin-top: 20px;
}


#comman-swiper-pagination .swiper-pagination-bullet-inactive {
    opacity: 1;
    background-color: #333;
    -webkit-appearance: none;
    border-radius: 0;
}



#comman-swiper-pagination .swiper-pagination-bullet {
    display: inline-block;
    height: 2px;
    width: 20px;
    -webkit-appearance: none;
}

#comman-swiper-pagination  .swiper-pagination {
    position: relative;
    margin-top: 5px;
}