
/* Match odds card matches row --------------------- */
.exchange-data-card-matches-row {
    width: 100%;
    margin: auto;
    margin-top: 1px;
    margin-top: 0px;
    border: 1px solid #D9D9D9;;
    border-left: 0px;
    border-right: 0px;

}

.exchange-data-card-matches-names-wrapper {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* end of matches odds mathes rows ----------------- */



.exchange-data-match-bets-title {
    font-family: var(--font-family);
    color: var(--scorcard-bets-title-color);
    font-size: 12px;
    font-weight: 550;
    margin-left: 4px;
    
}

.exchange-data-match-bets-title {
    width: 55%;
}

.exchange-data-back-lay-boxes {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.exchange-data-back-box {
    width: 48%;
    height: 55px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--exchange-data-back-box-bg);

}

.exchange-data-bet-value {
    font-family: var(--font-family);
    color: var(--exchange-data-bet-value-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}

.exchange-data-bet-amount {
    font-family: var(--font-family);

    color: var(--exchange-data-bet-amount-color);
    margin-top: 3px;
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
}


.exchange-data-lay-box {
    width: 48%;
    height: 55px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--exchange-data-lay-box-bg);
}