.iframe-live-chat {
    height: 90vh;
    margin-top: 60px;
}

@media (max-width: 800px) {
    
    .iframe-live-chat {
        margin-top: 50px;
        height: 94vh;
    }
}