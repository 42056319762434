@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400&display=swap");
/* body {
  background-color: #ddd;
} */

.home-container{
  background-image: url("../../Assets//WebIcons//bgBanner.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonials {
  margin-top: 80px;
}

/* .carousel-indicators {
  display: none !important;
} */

.carousel-indicators button {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid transparent !important;
  border-radius: 10px;
}

.carousel-control-prev {
  display: none !important;
}

.carousel-control-next {
  display: none !important;
}

.icon-boxes {
  padding: 0px;
}

.svgdot {
  float: right;
  margin-right: 10px;
  margin-top: 6px;
  font-size: 15px;
  padding: 0 2px;
}

.secpd {
  padding-top: 0 !important;
  padding-bottom: 0px;
  background: #EAEAEA;
}

section {
  padding: 60px 0;
  overflow: hidden;
  position: relative;
}


.sidenav1 a {
  padding: 6px 8px 6px 15px;
  text-decoration: none;
  font-size: 14px;
  color: #000;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #ddd;
  font-family: "Roboto Slab";
  font-weight: 400;
}

.abtp {
  margin-top: 8px;
}

.pointer {
  cursor: pointer;
}

.toggImg {
  width: 25px;
  height: auto;
  margin-right: 20px;
}

.betsl {
  width: 100%;
  display: flex;
  border: beige;
  border-bottom: 1px solid #5b5b5b;
  margin-bottom: 10px;
  color: #fff;
}

.lnkTb {
  background-color: #fff;
  height: 200px;
  width: 100%;
  text-align: center;
}

.bsp {
  background-color: #c0c0c0;
}

.btslp {
  color: #000;
  font-size: 12px;
  padding: 5px 15px;
  margin-right: 1px;
  text-decoration: none;
}

.stk {
  background: linear-gradient(to right, #436ad8 0, #b264b9 100%) !important;
  color: #fff;
}

.colsp .crvimg img {
  border-radius: 0;
}

.evol-label img {
  margin-top: 7px;
}

.topdiv {
  margin-top: -13px;
}

.list-logo {
  float: left;
  width: 20px;
  height: auto;
  margin-right: 6px;
  margin-left: 4px;
  margin-top: 2px;
}

.imgOuter {
  /* margin: 20px 0; */
  margin-top: 27px;
}

.tpgame-sec {
  background-image: url(../../images/casin-bg.png);
  background-repeat: no-repeat !important;
  background-color: #000 !important;
  background-size: 230px 35px !important;
  background-position: bottom right !important;
  height: 36px !important;
}

.crdGame {
  background-color: #fff;
  height: 34px;
  padding: 5px;
  color: #3e3e3e;
  margin: 10px 11px 5px 11px;
  margin-bottom: -5px;
}

.list-logo {
  float: left;
  width: 20px;
  height: auto;
  margin-right: 6px;
  margin-left: 4px;
  margin-top: 2px;
}

p.crdNmar {
  color: #fff;
}

.md-rwo {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.colsp .crvimg {
  margin: 0px 0;
}

.indTp .crvimg {
  padding: 0 8px;
}

.crvimgrst .crvimg .member-img {
  cursor: pointer;
  display: flex;
  margin: 6px 0;
  justify-content: center;
  justify-self: center;
}

.indTp.colsp .crvimg img {
  border-radius: 0px;
}

.member-img img {
  border-radius: 10px !important;
}



.carousel-image-qtech {
  min-width: 170px;
  height: auto;
}

.topmar {
  margin-top: -8px;
}

.tps1 {
  margin-top: 0px;
  margin-bottom: 20px;
}

.evol-label.evol-label-1 {
  background-image: url(../../images/33.png) !important;
}

.evol-label {
  color: #fff;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  margin-bottom: 0;
  background-image: url(../../images/3.png) !important;
  background-size: 230px 35px !important;
  background-position: bottom right !important;
  height: 36px !important;
  background: no-repeat;
  border-radius: 14px;
}

.evol.cube {
  background-image: url(../../images/img-2.png);
  background-repeat: no-repeat;
  background-color: #8953e7;
  background-size: 109px 33px;
  background-position: bottom right;
}

.evol {
  border-radius: 0;
  margin-bottom: 7%;
  background-image: url(../../images/Img-1.png);
  background-repeat: no-repeat;
  background-color: #8953e7;
  background-size: 58px 35px;
  background-position: bottom right;
  margin-top: 7%;
}

.MobileFooter-outer {
  display: none;
}

.web-sidenav {
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
}

@media (max-width: 600px) {
  .mainBannerImage {
    display: flex;
    width: 100%;
    height: 175px !important;
    border-radius: 0px;
    cursor: pointer;
  }
}

@media (max-width: 1200px) {
  .MobileFooter-outer {
    display: block;
  }

  .web-mnu {
    display: none !important;
  }

  .container,
  .container-sm {
    max-width: 100%;
  }

  .marg-mob {
    margin-top: 1rem !important;
  }

}

@media (min-width: 1200px) {
  .topdiv {
    margin-top: 0px;
  }

  .popup-hdr {
    background-color: #fff;
    position: absolute;
    top: 7%;
    height: 208px;
    width: 12%;
    right: 0%;
    z-index: 9999;
  }

  .icon-boxes {
    margin-top: 5px;
  }

  .InfiniteCarouselSlide {
    width: 249px !important;
  }

  .member-img img {
    width: 100% !important;
    border-radius: 10px !important;
  }

  .carousel-image-qtech {
    max-width: 150px !important;
    height: auto;
  }
}

@media (max-width: 1100px) {


  .carousel-image-qtech {
    min-width: 155px;
    height: auto;
  }
}


.MobileFooter_mobileFooter {
  background-color: #30469C;
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 55px;
  left: 0;
  margin-left: 0;
  position: fixed;
  transition: bottom 0.2s ease-in-out, background 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.MobileFooter_footerItem {
  flex: 1;
  max-height: 100%;
  opacity: 0.78;
  position: relative;
}

.MobileFooter_itemContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  text-align: center;
  text-decoration: none;
}

.MobileFooter_icon {
  height: 30px;
  margin: 3px auto 3px;
  object-fit: contain;
  cursor: pointer;
  width: 30px;
}

.MobileFooter_label {
  color: white;
  font-size: 12px;
  text-align: center;
  /* font-weight: 500; */
  font-family: Roboto Slab;
}

.md-rwo .col-6 {
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 1200px) {
  .md-rwo {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .imgOuter {
    /* padding-bottom: 10px; */
  }

  .carousel-image-qtech {
    max-width: 150px !important;
    height: auto;
  }
}

@media (max-width: 520px) {
  .evol-label {
    color: #fff;
    font-size: 13px;
    height: 29px;
    line-height: 29px;
    margin-bottom: 0;
  }

  .evol {
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .evol-label img {
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .evol-label {
    background-image: url(../../images/new-home-images/3333.png) !important;
  }

  .evol-label.evol-label-1 {
    background-image: url(../../images/new-home-images/333.png) !important;
  }
}

@media (max-width: 440px) {
  .evol-label {
    color: #fff;
    height: 29px;
    line-height: 29px;
    margin-bottom: 0;
    height: 28px !important;
  }

  .evol {
    margin-bottom: 2px;
    margin-top: 8px;
  }

  .list-logo {
    width: 20px;
    margin-right: 2px;
  }

  .carousel-image-qtech {
    min-width: 190px;
    height: auto;
  }
  .imgOuter{
    margin-top: 40px;
  }
}

@media (max-width: 400px) {


  .carousel-image-qtech {
    min-width: 170px;
    height: auto;
  }
  .imgOuter{
    margin-top: 45px;
  }
}

@media (max-width: 380px) {
  .evol-label {
    color: #fff;
    font-size: 13px;
    height: 29px;
    line-height: 29px;
    margin-bottom: 0;
  }

  .evol {
    margin-bottom: 5px;
    margin-top: 6px;
  }

  .carousel-image-qtech {
    min-width: 160px;
    height: auto;
  }
}

@media (max-width: 340px) {
  .evol-label {
    font-size: 11px;
    line-height: 27px;
  }

  .carousel-image-qtech {
    min-width: 150px;
    height: auto;
  }
}

@media only screen and (max-width: 290px) {
  .loginbtn {
    font-size: 10px;
  }

  .msgId {
    font-size: 15px;
  }

  .profile-list li a span {
    font-size: 10px;
  }

  .tpNv {
    font-size: 10px;
  }

  .logo a img {
    width: 110px;
  }

  .get-started-btn {
    font-size: 8px;
  }

  .social-media li {
    display: inline-block;
    width: 125px;
  }

  .social-media li a span {
    font-size: 13px;
  }

  .logou-bx .btn.btn-red {
    padding: 5px;
  }

  .evol-label {
    line-height: 16px;
    height: 19px !important;
  }

  .evol-label img {
    margin-top: 1px;
  }

  .list-logo {
    width: 15px;
  }
}

.imgcarousel {
  width: 160px;
  height: 100px;
}

.viewall {
  color: #fff;
  float: right;
  font-size: 12px;
  margin: 4px 10px;
}

.InfiniteCarouselDot {
  display: none !important;
}

.InfiniteCarouselArrowIcon {
  padding: 5px !important;
}

/* .InfiniteCarouselSlide {
  width: 293.667px ;
} */

.home-outer-container {
  width: 100%;
  display: flex;
  margin-top: 45px;
  justify-content:space-between;
}

.home-container {
width: 100%;
  display: flex;
}

.side-drawer {
  position: fixed;
  height: 100%;
  top: 0;
  right: 100%;
  width: 100%;
  z-index: 999;
  transform: translateX(0);
  transition: transform 0.3s ease-out;
}
.side-drawer-open {
  transform: translateX(100%); /* changed it */
}

.right-side-drawer {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0; /* Position the sidebar at the right edge of the viewport */
  width: 85%;
  z-index: 999;
  transform: translateX(100%); /* Translate the sidebar off-screen to the right */
  transition: transform 0.3s ease-out;
}

.right-side-drawer-open {
  transform: translateX(15%); /* Translate the sidebar back onto the screen */
}
