.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

#game-swiper .swiper-button-next {
    color: #000 !important;
    background-color: #D8E150;
    width: 30px;
    height: 30px;
    opacity: 0.4;
    border-radius: 15px;
    margin-top: -27px !important;
}

#game-swiper .swiper-button-next::after {
    font-size: 18px;
    color: #30469C;
}

#game-swiper .swiper-button-prev {
    color: #000 !important;
    background-color: #D8E150;
    width: 30px;
    height: 30px;
    opacity: 0.4;
    border-radius: 15px;
    margin-top: -27px !important;
}

#game-swiper .swiper-button-prev::after {
    font-size: 18px;
    color: #30469C;
}

#game-swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #30469C;
    -webkit-appearance: none;
    border-radius: 0;
}

#game-swiper .swiper-pagination-bullet-inactive {
    opacity: 1;
    background-color: #333;
    -webkit-appearance: none;
    border-radius: 0;
}

.swiper-container{
   
    margin-top:5px !important;
}
.swiper-container.main{
    max-width: 100%;
}

#main-banner-swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #31469C;
    -webkit-appearance: none;
    border-radius: 0;
    margin-top: 20px;
}


#main-banner-swiper .swiper-pagination-bullet-inactive {
    opacity: 1;
    background-color: #333;
    -webkit-appearance: none;
    border-radius: 0;
}



#main-banner-swiper .swiper-pagination-bullet {
    display: inline-block;
    height: 10px;
    width: 10px;
    clip-path: circle();
    -webkit-appearance: none;
}

#main-banner-swiper  .swiper-pagination {
    position: relative;
    margin-top: 15px;
   
}