:root{
    --scorecard-header-color:#D6DF3C;
    --back-box-color:#0F36AC;
    --lay-box-color:#E85D4A;
    --font-family: "Inter", sans-serif;
    --white-color:#ffffff;

}
.live-session-section{
    width: 100%;
    padding-top: 0px;
    margin-top: 0px;
}
.live-session-section-header{
    height: 80px;
    background-color: var(--scorecard-header-color);
    position: relative;
}
.live-session-header-heading{
    width: 100%;
    text-align: center;
    padding-top: 5px;
    color: #2947A2;
    font-family: var(--font-family);
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 630;
}

.live-session-navigation-tabs{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    position: absolute;
    bottom: 1px;
    left:40px;
 
}
.match-states-tab{
    font-family: var(--font-family);
    color: #2947A2;
    font-size: 1rem;
   position: relative;
   font-weight: 600;
   cursor: pointer;
}
#active-tab::after{
    content: "";
    width: 100%;
    height: 4px;
    background-color: #2947A2;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.watch-match-tab{
    font-family: var(--font-family);
    color: #2947A2;
    font-size: 1rem;
    display: flex;
    font-weight: 600;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.live-tv-icoon{
    font-size: 25px;
    color: var(--white-color);
    margin-left: 10px;
}
.watch-match-tab .tv-img{
    width: 15px;
    height: 15px;
    margin-left: 4px;
}



/* score card styling  */