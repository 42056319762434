
  .sidenav1 {
    width: 95%;
    background-color: transparent !important;
    margin: 5px 0;
    padding-right: 8px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  .sidenav1 a {
    padding: 6px 8px 6px 15px;
    text-decoration: none;
    font-size: 14px;
    color: #2947A2 !important;
    display: block;
    transition: 0.3s;
    border-bottom: 1px solid #808080 !important;
    font-family: "Roboto Slab";
    font-weight: 400 !important;
  }



  #active-side-menu::before{
    content: "";
    width: 6px;
    height: 30px;
    background-color: #D8E14F;
    position: absolute;
    left: 0px;
    border-radius: 16px;
  }
