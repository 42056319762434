.table-filters-search-box {
    height: 35px;
    width: 100%;
}

.table-filters-select-box {
    height: 35px;
    width: 100%;
}


.table-filters-date-box {
    height: 35px;
    width: 100%;
}
