.top-text{
padding-left: 10px;
}
.img-conetent-games{
    width: 100px;
}
.game-con{
    display: flex;
    flex-wrap: nowrap !important;
}
.indian-lobby-game{
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    flex-direction: row;
    display: flex;
}
.indian-game-image{
    width: 280px;
    height: 300px;
    margin: 5px;
}

@media (max-width: 1550px) {
    
    .indian-lobby-game{
        /* margin: 5px; */
    }

}

@media (max-width: 1050px) {
    
    .game-lobby-row{
        margin: 5px;
    }

}

@media (max-width: 1000px) {
    
    .game-lobby-row{
        margin: 5px;
    }

}

@media (max-width: 830px) {
    
    .game-lobby-row{
        margin: 5px;
    }

}

@media (max-width: 780px) {
    
    .game-lobby-row{
        margin: 5px;
    }

}




