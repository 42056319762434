.Bonus-container {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.inner-bonus-container {
    /* display: flex; */
    width: 95%;
}
.text-align{
    text-align: center;
}

/* .Card-container {
    min-Width: 338px ;
  
} */
.Card-container{
    border-radius: 24px !important;
    box-shadow: 0px 0px 6px 1px #00000040 !important;
}
.commom{
    display: flex;
    justify-content: center;
}
.bold{
    font-weight: 600 !important;
}
.Inner-content{
    text-align: center;
    font-weight: 400;
    font-size: 22px;
    color: #31469C;
    line-height: 26px;
}
.content-text{
    line-height: 29px;
    font-size: 25px;
    text-align: center;
    font-weight: 400;
    color: #31469C;
}
.Tranfer-details{
/* background-color: #D8E14F; */
font-size: 18px !important;
font-weight: 600;
line-height: 27px !important;
letter-spacing: 0em;
text-align: center;
margin-top: 1%;
border-radius: 6px ;
width: 100%;
display: flex;
justify-content: center;

}
.Inner-content p{
    margin-bottom: 0rem;
    font-size: 13px;
    line-height: 20.5px;
}
.top{
    margin-top: 12px;
}
.color-bg{
    background: #fff !important;
    color: #000000;
    padding: 7px!important ;
}
.Width-card{
    width: 100%;
}

.Second-content{
/* color: #000000; */
font-size: 13px;
font-weight: 600;
/* line-height: 5px; */
letter-spacing: 0em;
text-align: left;
padding-left: 10px 

}

.span-content{
    text-align: center;
    background-color: #D8E14F;
    padding: 5px;
    border-radius: 6px 
}
.Bonus-balance{
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px 
}
.color-bg p{
    margin-bottom: 0rem;
}
.MuiCardMedia-root {
    object-fit: fill !important;
}
.MuiCardContent-root {
    padding: 5px !important;
}
.Gird-container{
    padding: 6px 15px !important;
}

@media (width:280px){
    .inner-bonus-container{
        width: 100%;
    }
    .content-text{
        font-size: 15px;
        line-height: 17px;
    }
    .Tranfer-details{
        font-size: 13px !important;
        line-height: 20px !important;
    }
    .Inner-content p{
        line-height: 12.5px;
        font-size: 14px;
    }
    .MuiCardMedia-root {
        object-fit: fill !important;
    }
    .Inner-content {
        font-size: 18px;
        line-height: 20px;
    }

}
.card-wrapper {
    width: 50%; /* Two cards in one line on desktop */
    box-sizing: border-box;
    padding: 10px; /* Adjust spacing between cards */
  }
  
  @media (max-width: 767px) {
    .card-wrapper {
      width: 100%; /* One card per line on mobile */
    }
    .Tranfer-details{
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 1%;
    }
    .content-text{
        font-size: 20px;
    }
    .Gird-container{
        padding: 0px !important;
    }
  }
  .arrow-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }