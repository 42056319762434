:root {
    --session-and-premium-session-header-bg: #2947a2;
    --inplay-sectin-header-color: #2947A2;
    --font-family: "Inter", sans-serif;
    --session-runs-heading-color: #333333;
    --white-color: #ffffff;
    --yes-no-label: #333333;
}



.fancy-section {
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
}

/* fancy section header styling ------------------------------------ */
.fancy-section-header {
    width: 100%;
    height: 50px;
    background-color: #fff;
}

.fancy-section-marker {
    width: 186px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D6DF3C;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}

.fancy-section-marker::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #2947A2;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.fancy-section-navigation-tabs {
    width: 95%;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.sessino-tab {
    width: 33%;
    height: 30px;
    font-family: var(--font-family);
    color: #333333;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sessino-tab:first-child {
    width: 29%;
}

#fancy-active-tab {
    background-color: #D6DF3C;
}

#fancy-active-tab::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #2947A2;
    position: absolute;
    bottom: 0px;
    left: 0px;
}


/* fancy section session run sectio ------------------------------ */

.session-runs-section {
    width: 100%;
    margin: auto;
    margin-top: 20px;
}

.session-runs-heading {
    width: 95%;
    margin: auto;
    font-size: 1rem;
    font-family: var(--font-family);
    color: var(--session-runs-heading-color);
    margin-top: 20px;
    padding-top: 10px;
    font-weight: 600;
}

.session-runs-header {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.session-heading {
    width: 60%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    color: #333333;
  

}

.fancy-back-lay-labels {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.fancy-lay-label {
    width: 48%;
    text-align: center;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: bolder;
    font-family: var(--font-family);
    color: var(--yes-no-label);
    display: flex;
    align-items: center;
    justify-content: center;
}

.fancy-back-label {
    width: 48%;
    text-align: center;
    margin-left: 0px;
    padding-left: 0px;
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: bolder;
    color: var(--yes-no-label);
    display: flex;
   
    align-items: center;
    justify-content: center;

}

.session-runs-list {
    margin-top: 10px;
}