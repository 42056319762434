.bonus-wallet-terms-header {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bonus-wallet-terms-header-text {
    font-size: 28px;
    color: #30469C;
    font-weight: bold;
    margin-bottom: 0;
}

.bonus-wallet-terms-body {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bonus-wallet-terms-text {
    font-size: 14px;
    color: #30469C;
    margin-bottom: 0;
    padding: 10px 0;
    text-align: left;
}


.bonus-wallet-terms-text-bold {
    font-size: 18px;
    color: #30469C;
    margin-bottom: 0;
    padding: 10px 0;
    text-align: left;
    font-weight: bold;
}

.bonus-wallet-terms-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 90px;
    z-index: 99;
}

.bonus-wallet-ref-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.language-dropper-container {
    width: 140px;
    position: absolute;
    right: 0;
}