.modal-content-ext .modal-content {
  background-color: transparent;
  border: none;
}

.register-popup-img {
  width: 100%;
  margin-top: -30px;
  border-radius: 15px;
}

.cross-btn-register{
  float: right !important;
  padding: 8px !important;
  margin-right: 12px !important;
}

.close-btn-div{
  margin-right: 12px;
}