.message-inp {
  height: 6rem !important;
}

@media (min-width: 1200px) {
  .feedback-web {
    margin: 50px auto !important;
  }
}

