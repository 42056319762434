
.sport-match-details-header-marker {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.sport-match-header-content {
    width: 55%;
    padding-left: 0px;
}
.sport-icon-and-name-wrapper{
    width: 50%;
    max-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #2947A2;
    border-radius: 12px;
    padding-left: 0px;
    padding:2px 0px 2px 0px;
    margin-left: 8px;
}
.sport-icon-and-name-wrapper.desktop{
    width: 30%;
}

.inplay-sport-table-image{
    width: 20px;
    height: 20px;
    margin-right: 4px;
   
}

#Cricket{
    width: 20px;
    height: 20px;
}
#Tennis{
    width: 25px;
    height: 25px;
    margin-top:4px;
}
#Tennis img{
    width: 100%;
    height: 100%;
  
}
.inplay-sport-table-image img {
    width: 100%;
    height: 100%;
    margin-top: -10px;
}

.inplay-sport-table-name {
    font-family: var(--font-family);
    font-size: 1rem;
    color: var(--white-color);
    font-weight: 400;
    text-transform: capitalize;
}

.back-lay-marker {
    width: 50%;
    display: flex;
    justify-content: space-between;
   
}
.back-marker {
   flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: var(--black-color);
}
.vs-icon {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: var(--black-color);
}

.lay-marker {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: var(--black-color);
}