html {
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

.top_menu {
  background: #242424;
  border-bottom: 1px solid #4d4b4b;
  height: 27px;
  margin-top: 40px;
}

.top_menu ul {
  text-align: center;
  margin: 0;
  padding: 0;
}

.acc-table {
  padding: 10px;
  margin-top: 10px;
}

.accountDetail {
  width: 100%;
}

.accountDetail tr td {
  color: #fff;
  padding: 5px 0;
  font-size: 13px;
}

.txt-right-table {
  text-align: right;
}

.bor-px {
  border-top: 1px solid #aa53c9;
}

.tdkyc {
  margin-bottom: 4px;
}

.kycApproved {
  color: #278931;
  font-weight: bold;
}

.kycStatus {
  font-size: 21px;
  padding-left: 0px;
  background: #436ad8;
  background: linear-gradient(to right, #436ad8 0%, #b264b9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profile-bx a {
  margin-right: 0;
}

.profile-bx a {
  display: inline-flex;
  text-decoration: none;
  color: #fff;
  /* float: right; */
  position: relative;
  margin-right: 20px;
  line-height: 26px;
}

.accountDetail tr td {
  color: #fff;
  padding: 5px 0;
  font-size: 13px;
}

.txt-right-table {
  text-align: right;
}

.colorchangebg_2 img {
  width: 30px;
  display: inline-block;
}

.deposit_section.whitdepo {
  font-size: 14px;
  padding: 2px 4px;
  display: inline-block;
  height: 26px;
}

.deposit_section {
  display: block;
  border: 1px solid #acaaaa;
  padding: 14px 18px;
  font-size: 22px;
  color: #ffff;
}

.top_menu ul li {
  display: inline-block;
}

.white-form-dv {
  background-color: #fff;
  padding: 10px;
  padding-bottom: 40px;
}

.top_menu ul li a {
  color: #ffffff;
  padding: 0px 12px;
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
}

.useren {
  color: #000;
  font-size: 20px;
  margin-bottom: 0;
}

.userLogindetail {
  color: #d2d2d2;
  font-size: 12px;
  margin-top: 0;
}

.unstylebord-none {
  border: 0 !important;
  padding-left: 0;
}

.profile-list li.smbet-his {
  width: 100% !important;
}

.profile-list.white-list li {
  width: 50%;
  padding: 4px 0;
  border-bottom: 0;
  text-align: left;
  padding-left: 2px;
}

.profile-list li {
  padding: 30px 9px;
  border-bottom: 1px solid #ddd;
  margin-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 12px;
  color: #fff;
}

.profile-list li {
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
  margin-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 15px;
  display: inline-block;
  width: 33%;
  text-align: center;
}

.profile-detail {
  /* top: 35px; */
  /* right: 0px; */
  /* background: #fff; */
  /* padding: 0; */
  /* width: auto; */
  border-radius: 0 px;
  /* z-index: 9999; */
  /* position: fixed; */
  background: #fff;
}

.profile-list li.smbet-his a {
  text-align: center;
}

.profile-list.white-list li a {
  padding: 5px 0;
}

.profile-list li a {
  display: block;
  color: #444343;
  font-weight: 500;
  border: 1px solid #e3e3e3;
}

.white-list a {
  margin-right: 0 !important;
}

.profile-bx a {
  display: inline-flex;
  text-decoration: none;
  color: #fff;
  /* float: right; */
  position: relative;
  margin-right: 20px;
  line-height: 26px;
}

.profile-list.white-list li a img {
  display: initial;
  margin: 0;
}

.colorchangebg img {
  margin-left: 3px !important;
}

.colorchangebg img {
  width: 34px;
}

.profile-list.white-list li a {
  padding: 5px 0;
}

.profile-list li a {
  display: block;
  color: #444343;
  font-weight: 500;
  border: 1px solid #e3e3e3;
  text-decoration: none;
}

.white-list a {
  margin-right: 0 !important;
}

.socialwhite {
  background-color: #fff !important;
  padding-left: 0;
}

.social-media li {
  display: inline-block;
  width: 153px;
}

.social-media.socialwhite li a {
  color: #000;
}

.profile-bx a {
  margin-right: 0;
}

.social-media li a {
  color: #f2f2f2;
  padding: 11px;
  font-size: 18px;
  text-decoration: none;
}

.social-media li a span {
  padding-top: 8px;
  padding-left: 9px;
  vertical-align: top;
  line-height: 32px;
}

.text-center {
  text-align: center !important;
}

.logou-bx {
  margin: 0;
  padding: 0;
}

.logou-bx .btn.btn-red {
  background: #8c0000;
  color: #fff;
  font-weight: 600;
  width: 36%;
  border-radius: 0 0 3px 3px;
  background: #436ad8;
  background: -moz-linear-gradient(left, #436ad8 0%, #b264b9 100%);
  background: -webkit-linear-gradient(left, #436ad8 0%, #b264b9 100%);
  background: linear-gradient(to right, #436ad8 0%, #b264b9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#436ad8', endColorstr='#b264b9', GradientType=1);
  margin: 4px;
  border: 0;
  padding: 10px;
  border-radius: 5px;
}

.profile-list li a span {
  vertical-align: top;
  line-height: 32px;
  color: #444343;
}

.with {
  margin: 2px 2px;
  float: left;
}

.with1 {
  margin: 0px 2px !important;
}

.img-profile {
  width: 25px;
}

.borderbtm {
  border-bottom: 1px solid #ddd;
}

.profile1 {
  width: 18px;
  margin-left: 6px;
  margin-top: -23px;
}

.profilepg {
  margin: 0 20px;
  line-height: 50px;
  padding-top: 30px;
}

.mr-right {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  font-family: math;
  justify-content: center;
  display: flex;
  margin-top: -41px;
  margin-bottom: -10px;
}

.potion {
  margin: 3px;
}

.potion1 {
  border: 1px solid #c9c6c6;
}

.selected {
  border: 1px solid black;
}

.profile-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
}

.card-content {
  cursor: pointer !important;
  padding-bottom: 0 !important;
  padding: 0 !important;
}

.MobileFooter_mobileFooter1 {
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 45px;
  left: 0;
  margin-left: 0;
  position: fixed;
  transition: bottom 0.2s ease-in-out, background 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
  border-top: 1px solid #ddd;
}

.MobileFooter_footerItem1 {
  flex: 1 1;
  max-height: 100%;
  opacity: 0.78;
  position: relative;
  display: flex;
  align-items: center;
}

.MobileFooter_itemContent1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  text-align: center;
  text-decoration: none;
}

.MobileFooter_label1 {
  color: #000;
  font-size: 10px;
  text-align: center;
  padding-left: 22px;
  font-family: Roboto Slab;
}

.MobileFooter_label1-alt {
  color: #30469C;
  font-size: 15px;
  text-align: center;
  padding: 5px 0;
  font-weight: 600;
}

.footera {
  text-decoration: none;
}

.profile-help-button {
  text-decoration: none;
}

.mobile-footer-divider {
  background-color: #30469C;
  height: 1px;
  margin: 2px 0;
}

.help-box-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  margin: 0 5px;
  background-color: #D8E150;
  border-radius: 10px;
}

.help-hover-box {
  position: absolute;
  background-color: #D8E150;
  border-radius: 6px;
  padding: 0 30px;
  height: 80px;
  bottom: -120px;
  left: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-logout {
  margin: 5px !important;
  border-radius: 0 !important;
  background: #D8E150 !important;
}

.pro-footer {
  background: #30469C;
}

.profile-text-content {
  padding: 8px 8px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  /* font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  color: #3C3E3D; */

}

.profile-span-text-content {
  float: right;

}

.hr-content {
  border: 1px solid #C3C5C4;
  margin: 0.2rem 0;
}

.user-main-container {
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-container {
  /* width: 60%; */
  /* padding: 3px 8px !important; */
  /* font-family: "Roboto Slab"; */
  /* font-size: 15px; */
  /* border: none !important; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* margin-top: -5px; */
  padding: 0 8px;
  display: flex;
  flex-direction: column;
}

.deposit-container {
  width: 40%;
}

.deposit-button {
  padding: 10px 20px;
  text-decoration: none;
  color: #3C3E3D;
  border: 1px solid #C3C5C4;
  transition: background-color 0.3s ease;
}

.deposit-text {
  color: #3C3E3D;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.bonus-text {
  width: 40%;
  padding: 3px 8px;
  display: flex;
  flex-direction: column;
}

.copy-icon {
  width: 20px;
  height: 20px;
  margin-inline-start: 5px;
}

.referalCodeClick {
  display: flex;
  flex-direction: row;
  align-items: 'center';
}

.whatsapp-img {
  width: 30px;
  height: 40px;
}

.whatsAppContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  cursor: pointer;
}

.whatsapp-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ECECEC;
  padding: 5px 10px;
  justify-content: center;
  border-radius: 15px;
}

.whatsapp-content {
  color: #4CAF50;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.new-help-box {
  display: flex;
  flex-direction: row;
}

.help-box-horizontal {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.profile-tabs {
  background: transparent !important;
  color: #3C3E3D !important;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.MuiTabs-indicator {
  background-color: #31469C !important;
}

.selected-tab {
  font-weight: 700 !important;
  color: #000 !important;
}

.tabs-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* padding-right: 20%;
    padding-left:  20%; */
  margin-right: auto;
  margin-left: auto;
  /* justify-content: space-between; */
  /* line-height: 50px;
    margin: 0 15px; */
}

.tab-img {
  height: 120px;
  width: 120px;
}

.tabs-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #3C3E3D;
}

.tabs-sub-container {
  width: 32.4%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.text-content {
  /* margin-bottom: 0.2rem; */
  padding: 3px 8px !important;
  font-size: 16px;
  font-weight: 500;
  width: 75px;
  margin-bottom: 0;
  color: #fff;
  background-color: #040F2E;

}

.tabs-box-container {
  width: 100%;
  background-color: #ececec8a;
}

.footer-button{
width: 250px;
  max-width: 350px;
  border-radius: 8px;
  justify-content: center;
  display: grid;
  margin-left: 5px;
  margin-bottom: 5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

@media screen and (min-width: 1200px) {
  .profilepg {
    line-height: 70px;
  }

  .profile1 {
    width: 25px;
    margin-left: 15px;
  }

  .mr-right {
    margin-left: 18px;
    font-size: 17px;
    font-weight: 500;
  }

  .tabs-main-container {
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
  }

  .common-style-profile {
    /* padding-right: 40px;
    padding-left: 40px; */
    margin-right: auto;
    margin-left: auto;
    display: flex;
    width: 80%;
    background-color: #ececec8a;
  }

  .common-style-profile-mobile {
    /* padding-right: 40px;
    padding-left: 40px; */
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    background-color: #ececec8a;
  }

  .img-content {
    font-size: 25px;
  }

  .whatsapp-content {
    font-size: 22px;
  }

  .tabs-container {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media screen and (min-width: 1024px) {
  .tabs-container {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media screen and (max-width: 600px) {
  .colorchangebg img {
    width: 24px;
  }

  .profile-list li a span {
    vertical-align: top;
    line-height: 25px;
  }

  .profile-list.white-list li {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 280px) {
  .deposit-text {
    font-size: 12px;
  }

  .deposit-button {
    padding: 8px;
  }

  .tab-img {
    height: 70px;
  }

  .tabs-content {
    font-size: 13px;
  }

  .tabs-container {
    padding-right: 10px;
    padding-left: 10px
  }

}

/* @media screen and (max-width: 440px) {
  .profile-list.white-list li {
    width: 49.3%;
  }
} */