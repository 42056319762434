
.top-categories-section{
    margin-top: 30px;
}
.game-page-banners-container {
    display: grid;
    grid-template-columns: repeat(4, 0.35fr);
    /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
    list-style: none;
    margin-left: 0;
    -webkit-box-pack: justify;
    align-content: flex-start;
}

.game-page-image {
    width: 270px;
    cursor: pointer;
}

@media (max-width: 1050px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(4, 0.30fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 180px;
        cursor: pointer;
    }

}

@media (max-width: 1000px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.30fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 233px;
        cursor: pointer;
    }

}

@media (max-width: 830px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.25fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 195px;
        cursor: pointer;
    }

}

@media (max-width: 780px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.25fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 184px;
        cursor: pointer;
    }

}


@media (max-width: 600px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.25fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 130px;
        cursor: pointer;
    }

}

@media (max-width: 450px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.2fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 128px;
        cursor: pointer;
    }

}


@media (max-width: 420px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.2fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 123px;
        cursor: pointer;
    }

}

@media (max-width: 395px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.25fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 116px;
        cursor: pointer;
    }

}

@media (max-width: 380px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.25fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 112px;
        cursor: pointer;
    }

}

@media (max-width: 365px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.15fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 108px;
        cursor: pointer;
    }

}

@media (max-width: 290px) {
    .game-page-banners-container {
        display: grid;
        grid-template-columns: repeat(3, 0.15fr);
        /* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
        list-style: none;
        margin-left: 0;
        -webkit-box-pack: justify;
        align-content: flex-start;
    }

    .game-page-image {
        width: 84px;
        cursor: pointer;
    }

}