.game-item-container {
    padding-bottom: 5px !important;
}

.game-item-container-mob {
    padding-bottom: 20px !important;
}

.game-item-image {
    display: flex;
    width: 490px;
    height: 20%;
    cursor: pointer;
}

.game-slider-header {
    margin-bottom: 5px
}

.game-slider-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 1000px) {
    .game-item-image {
        display: flex;
        width: 50%;
        height: 100%;
        cursor: pointer;
    }

}

@media (max-width: 600px) {
    .game-item-image {
        display: flex;
        width: 50%;
        height: 100px;
        cursor: pointer;
    }

}