.MuiButtonBase-root{
    background: #fff !important;
}
.MuiCardActions-root{
    padding: 0px !important;
}

.bazar-content{
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 0em !important;
    /* margin-bottom: 0px !important; */
}
.bazar-content-mob{
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 10px !important;
    letter-spacing: 0em !important;
}
.action-content{
    color: #fff;
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.action-card-container{
    background: #31469C;
     width: 100%;
}
.grid-container{
    padding: 0.5rem;
}
.action-time-content{
font-family: Inter;
font-size: 9px !important;
font-weight: 500 !important;
line-height: 15px !important;
letter-spacing: 0em !important;
color: #D8E14F ;
}
.content-color{
    color: #323131;
}
.sub-content{
font-family: Inter;
font-size: 10px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
color: #323131;
margin-bottom: 0px;
}
.bazar-result-content{
    font-size: 25px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    color: #323131;
}
.sub-container{
    display: flex;
    flex-direction: column !important;
}
.img-div{
    display: flex;
    justify-content: center;
    margin-bottom: 0.2rem
}
.topgm-heading{
    display: flex;
    justify-content: space-between;
}
.topgm-content{
    background: #292A8D;
    border-radius: 18px;
    color: #fff;
    padding:4px 11px;
}
@media (max-width:768px) {
    .topgm-content{
        padding: 3px 11px;
    }
    
}
@media (max-width:600px) {
    .swiper-satta-market{
        width: 205px !important;
    }
    .satta-market-image{
        height: 120px;
    }
}