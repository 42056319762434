.scorecard-section {
margin-top: 10px;
    padding-top: 0px;
}

.scorecard-total-scores-list {
    width: 100%;
    border-bottom: 4px solid #2947A2;
    padding-bottom: 15px;
}

.live-match-odds-and-bookmaker{
    margin-top: 12px;
}
