.watch-live-section{
    width: 100%;
}
.live-match-iframe{
    width: 100%;
}
.live-match-img{
    width: 100%;
    height: auto;
}
iframe{
    width: 100%;
    height: 240px;
    margin: auto;
}