.tpNv1 {
  text-align: left !important;
}

.tpNv {
  text-align: center;
  display: inline;
  width: 100%;
  text-decoration: none;
  padding: 0 8px;
  color: #fff;
  font-size: 14px;
}

.tpDV1 {
  text-align: center;
  display: block;
  width: 100%;
  box-shadow: 0;
  margin-bottom: 0px;
}

.svgdot {
  float: right;
  margin-right: 10px;
  margin-top: 6px;
  font-size: 15px;
  padding: 0 2px;
}

.rupee {
  float: right;
  margin-right: 15px;
  margin-top: -30px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.acc-table {
  margin-top: 0;
}

.user-name {
  color: #fff;
  margin: 0 10px;
  font-family: "Poppins", sans-serif;
}

.tpDV1 p {
  margin-bottom: 8px;
  padding-top: 10px;
}

.exposure {
  float: right;
}

@media (max-width: 1200px) {
  .tpDV1 {
    margin-bottom: 0;
  }
}


.profile-header-container {
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  background-color: #040f2e;
  padding-bottom: 4px;
}

.profile-header-top {
  /* border-bottom: 1px solid #636363; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.profile-header-text-container {
  text-align: center;
  display: inline;
  text-decoration: none;
  padding: 0 8px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile-header-text-containerV2 {
  text-align: center;
  display: inline;
  text-decoration: none;
  padding: 0 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}