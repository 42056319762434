.top-satta-market-section {
    width: 100%;
    margin: auto;
    margin-top: 20px;
}



.top-satta-market-swiper-container {
    width: 98%;
    margin: auto;
    margin-top: 10px;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    margin: 14px;
    margin-top: 28px !important;
}

.sattaMarketGameSwiper {
    display: flex;

}

.satta-market-card {
    width: 300px;
    cursor: pointer;
}


.satta-market-card-img {
    width: 100%;
    border-radius: 15px;
}

.bazar-content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
}

.bazar-details {
    max-width: 75%;
    text-align: start;
}

.bazar-name {
    font-size: 16px;
    font-weight: 600;
    color: #231E28;
    font-family: "Poppins", sans-serif;
}

.bazar-rate {
    color: #31469C;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    font-family: "Poppins", sans-serif;
}

.runnig-mssg {
    font-size: 12px;
    font-weight: 400;
    color: #231E28;
    line-height: 14px;
    font-family: "Poppins", sans-serif;
}

.bazar-live-details {
    text-align: center;
}

.live-tv {
    font-size: 12px;
    font-weight: 400;
    color: #31469C;
    font-family: "Poppins", sans-serif;
}

.result {
    font-size: 12px;
    font-weight: 400;
    color: #31469C;
    font-family: "Poppins", sans-serif;
}

.bazar-open-close-box {
    width: 100%;
    border-radius: 11px;
    background-color: #31469C;
    margin-top: 3px;
    
}

.bazar-open-close-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 13px 7px 13px;
}

.bazar-open,
.bazar-close {
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.clocksvg{
    width: 30px;
    height: 30px;
}

.bazar-time {
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 600;
    font-family: var(--font-family);
}

.bazar-status {
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 300;
    line-height: 14px;
    font-family: var(--font-family);
}

.runnig-time {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #D8E14F;
}


@media screen and (max-width:768px) {
    .top-satta-market-swiper-container {
        margin-top: 10px;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 10px;
        margin-top: 14px;
    }

    .satta-market-card {
        width: 185px;
    }

    .bazar-details {
        max-width: 75%;
        text-align: start;
    }

    .bazar-name {
        font-size: 11px;
        text-wrap: nowrap;
        font-weight: 600;
        
        font-family: var(--font-family);
    }

    .bazar-rate {
        
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }

    .runnig-mssg {
        font-size: 10px;
        font-weight: 400;
       
        line-height: 14px;
        font-family: var(--font-family);
    }

    .bazar-live-details {
        text-align: center;
    }

    .live-tv {
        font-size: 10px;
        font-weight: 400;
       
        font-family: var(--font-family);
    }

    .result {
        font-size: 10px;
        font-weight: 400;
        
        font-family: var(--font-family);
    }

    .bazar-open-close-box {
        width: 100%;
        border-radius: 11px;
        
        margin-top: 3px;
    }

    .bazar-open-close-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 11px 3px 11px;
    }

    .bazar-open,
    .bazar-close {
        text-align: center;
    }

    .bazar-time {
        font-size: 11px;
        
        font-weight: 600;
        font-family: var(--font-family);
    }

    .bazar-status {
        font-size: 11px;
     
        font-weight: 300;
        line-height: 14px;
        font-family: var(--font-family);
    }

    .runnig-time {
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
       
    }

    


}

@media screen and (min-width:370px) and (max-width:390px) {
    .top-satta-market-swiper-container {
        margin-top: 10px;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 5px;
        margin-top: 14px;
    }

    .satta-market-card {
        width: 175px;
    }

    .bazar-details {
        max-width: 75%;
        text-align: start;
    }

    .bazar-name {
        font-size: 11px;
        text-wrap: nowrap;
       
        font-weight: 600;
       
        font-family: var(--font-family);
    }

    .bazar-rate {
       
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }

    .runnig-mssg {
        font-size: 10px;
        font-weight: 400;
        
        line-height: 14px;
        font-family: var(--font-family);
    }

    .bazar-live-details {
        text-align: center;
    }

    .live-tv {
        font-size: 10px;
        font-weight: 400;
        
        font-family: var(--font-family);
    }

    .result {
        font-size: 10px;
        font-weight: 400;
       
        font-family: var(--font-family);
    }

    .bazar-open-close-box {
        width: 100%;
        border-radius: 11px;
       
        margin-top: 3px;
    }

    .bazar-open-close-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 11px 3px 11px;
    }

    .bazar-open,
    .bazar-close {
        text-align: center;
    }

    .bazar-time {
        font-size: 11px;
       
        font-weight: 600;
        font-family: var(--font-family);
    }

    .bazar-status {
        font-size: 11px;
       
        font-weight: 300;
        line-height: 14px;
        font-family: var(--font-family);
    }

    .runnig-time {
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
        
    }

    

    
}


@media screen and (min-width:340px) and (max-width:370px){
 .top-satta-market-swiper-container {
        margin-top: 10px;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 5px;
        margin-top: 14px;
    }

    .satta-market-card {
        width: 165px;
    }
    .bazar-name {
        font-size: 11px;
        text-wrap: nowrap;
        font-weight: 600;
        font-family: var(--font-family);
    }

    .bazar-rate {
      
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }

    .runnig-mssg {
        font-size: 10px;
        font-weight: 400;
        
        line-height: 14px;
        font-family: var(--font-family);
    }

    .bazar-live-details {
        text-align: center;
    }

    .live-tv {
        font-size: 10px;
        font-weight: 400;
        
        font-family: var(--font-family);
    }

    .result {
        font-size: 10px;
        font-weight: 400;
        
        font-family: var(--font-family);
    }

    .bazar-open-close-box {
        width: 100%;
        border-radius: 11px;
        
        margin-top: 3px;
    }

    .bazar-open-close-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 11px 3px 11px;
    }

    .bazar-open,
    .bazar-close {
        text-align: center;
    }

    .bazar-time {
        font-size: 11px;
     
        font-weight: 600;
        font-family: var(--font-family);
    }

    .bazar-status {
        font-size: 11px;
        font-weight: 300;
        line-height: 14px;
        font-family: var(--font-family);
    }

    .runnig-time {
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
       
    }

}