.lobby-btn {
    border-radius: 7px !important;
    width: 99%;
    margin-left: -1%;
    background-color: #11246F !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0rem;
    height: 36.5px;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    color: #ffffff !important;
    text-align: center !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}