
:root{
    --place-match-odds-section-bg:rgba(228, 228, 228, 1);
    --place-match-odds-section-border:#2947A2;
    --place-bets-input-label-color:#333333;
    --odds-bet-increase-btn-bg:#2947A2;
    --bet-place-other-amount-btn-bg-color:#2947A2;
    --place-bet-button-bg:#0F36AC;
    --place-bet-button-text-color:#ffffff;
    --place-bet-toaster-bg:#0F36AC;
    --place-bet-toaster-text-color:#ffffff;

}

.place-match-bet-card-section{
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--scorecard-header-color);
    background-color: var(--place-match-odds-section-bg);
    padding-bottom: 20px;
    position: relative;

}
.conform-box{
    width: 70%;
    height: 120px;
    background-color: #fff;
    border-radius:28px;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999;
    padding-top: 20px;
    
}
.conform-message{
    text-align: center;
}
.buttons{
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}
.buttons button{
    width: 80px;
    height: 35px;
    border: 1px solid #D6DF3C;
    font-family: var(--font-family);
    font-weight: 500;
    border-radius: 12px;
    text-transform: capitalize;

}
.place-match-bet-card-section .place-bet-toaster{
   
    padding: 20px 12px 20px 12px;
    background-color: var(--place-bet-toaster-bg);
    color: var(--place-bet-toaster-text-color);
    font-family: var(--font-family);
    font-size: 1rem;
    z-index: 9999;
}
.place-match-bet-card-section-container{
    width: 100%;
    margin: auto;
}
.place-match-odds-and-match-stack-bet-fields{
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.odds-input-section{
    width: 45%;
}
.odds-input-field-header{
    padding-top: 10px;
}
.odds-input-field-label{
    font-family: var(--font-family);
    font-size: 0.9rem;
    text-transform: uppercase;
}
.odds-input-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
    box-sizing: border-box;
}
.odds-decrease-btn,.odds-increase-btn{
    width: 16%;
    height: 30px;
}
.odds-increase-btn button{
    width: 100%;
    height: 100%;
    background-color: var(--odds-bet-increase-btn-bg);
    color: var(--white-color);
    font-family: var(--font-family);
    font-size: 1rem;
    border: none;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.odds-decrease-btn button{
    width: 100%;
    height:100%;
    background-color: var(--odds-bet-increase-btn-bg);
    color: var(--white-color);
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: bolder;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.odds-bet-input{
    width: 68%;
}
.odds-bet-input input{
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    text-align: center;
    outline: none;
    font-family: var(--font-family);
    border: none;
    border-radius: 0px;
    box-sizing: border-box;
}

.odds-bet-input input::placeholder{
    font-family: var(--font-family);
    font-size: 1rem;
    color:rgba(51, 51, 51, 0.37) ;
}
.odds-bet-input input::-webkit-inner-spin-button,
.odds-bet-input input::-webkit-outer-spin-button{
    display: none;
}
/* Start the stake input section styling ------------------ */
.stack-input-section{
    width: 54%;
    margin-left: 5px;
    box-sizing: border-box;
}

.stack-input-section-labels-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.stack-input-label{
    font-family: var(--font-family);
    font-size: 0.9rem;
    text-transform: uppercase;
}
.max-mkt-amount{
    font-size: 0.8rem;
}
.stake-input-field{
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.stake-input-field input{
    width: 100%;
    height: 100%;
    outline: none;
    box-sizing: border-box;
    text-align: center;
    border: none;
    border-radius: 0px;
}
.stake-input-field input::placeholder{
    font-family: var(--font-family);
    font-size: 1rem;
    color:rgba(51, 51, 51, 0.37) ;
}
/* end of the bet inputs section ---------------------- */
/* start the bet other amouns sectio ------------------- */
.bet-other-amounts-section{
    width: 100%;
}
.bet-other-amounts-btns-container{
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap;
}
.other-amount-button{
    width: 32%;
    height: 25px;
    background-color: var(--bet-place-other-amount-btn-bg-color);
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-size: 0.9rem;
    color: var(--white-color);
    margin-top: 10px;
}

/* end of other amount-buttons------------------ */
.place-match-bet-buttons{
    width: 88%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}
.cancle-bet{
    width: 47%;
    height: 65px;
}
.cancle-bet-button{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid  rgba(105, 105, 105, 1);
    font-family: var(--font-family);
    font-size: 1rem;
    color: rgba(105, 105, 105, 1);
    font-weight: bolder;
    text-transform: capitalize;
    background-color: transparent;
}

/* coustom cancle confirm dilogbox button styling */
.place-bet-cancle-confirm-button{
    width: 135px;
    height: 40px;
    border: none;
    background-color: #0F36AC;
    color: #fff;
    font-family: var(--font-family);
    font-size: 0.9rem;
}
.place-bet-cancle-cancle-button{
    width: 135px;
    height: 40px;
    border: none;
    background-color: #e91100;
    color: #fff;
    margin-right: 5px;
    font-family: var(--font-family);
    font-size: 0.9rem;
}
/* place bet styling ---------------------- */
.place-bet{
    width: 47%;
    height: 65px;
}
.place-bet-button{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 12px;
    font-family: var(--font-family);
    font-size: 1rem;
    text-transform: capitalize;
    background-color: var(--place-bet-button-bg);
    color: var(--place-bet-button-text-color);
}
.place-bet-button .place-bet-btn-labl{
    font-weight: bolder;
    font-family: var(--font-family);
    font-size: 13px;
    color: var(--place-bet-button-text-color);
    font-weight: 500;
    line-height: 16px;
}
.place-bet-amount{
    font-family: var(--font-family);
    font-size: 13px;
    color: var(--place-bet-button-text-color);
    font-weight: 500;
    line-height: 16px;
}

/* end of the place bet buttons section --------------- */

.conform-bet-before-placing-message{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding-top: 20px;
}
.conform-bet-message{
    font-family: var(--font-family);
    font-size: 1rem;
    color: rgba(51, 51, 51, 1);
    
}


.Mui-checked{
    background-color: transparent;
}
