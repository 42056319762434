.language-dropper {
    background-color: #D8E150;
    padding: 2px 5px;
    border-bottom-left-radius: 8px;
    cursor: pointer;
}

.language-dropper-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.language-text {
    font-size: 14px;
    color: #30469C;
    margin-bottom: 0;
    font-weight: bold;
    margin-inline-start: 5px;
}

.language-text-alt {
    font-size: 12px;
    color: #30469C;
    margin-bottom: 0;
    padding: 5px 0;
    font-weight: bold;
    margin-inline-start: 5px;
}

.active-language {
    text-decoration: underline ;
}