
.open-bets-data-table-section{
    width: 100%;
   
}
.open-bets-data-table-section.all-open-bets{
    margin-top: 60px;
}
.open-bets-data-table-actions-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 107px;
    background-color: #2947A2;
}
.table-actions{
    width: 95%;
    margin: auto;
}
.action-buttons{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
  
}
.date-input,.select-market{
    width: 100px;
    height: 36px;
    background-color: #fff;
    border: none;
    outline: none;
    box-sizing: border-box;
    text-align: center;
    color: #333333;
    font-size: 13px;
    margin: 0px 10px;
    margin-left: 0px;
    font-weight: 600;
    line-height: 13px;
    
}
.select-market{
    margin-right: 10px;
}
.date-input:nth-child(1){
    margin-left: 0px;
}
.search-button{
    width: 127px;
    height: 36px;
    background-color: #D6DF3C;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 13px;

}
.action-label{
    width: 100%;
    margin: auto;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
}

.open-bets-data-table{
    width: 100%;
    overflow-x: auto;
}
 .table{
    width: 100%;
    border-collapse: collapse;
   
 }
 .table > thead tr th{
    background-color: #D9D9D9;
    font-size: 13px;
    color: #333333;
    border: 1px solid #B2B2B2;
    text-align: center;
    text-wrap: nowrap;
 }


.table > tbody tr td{
    border: 1px solid #B2B2B2;
    font-size: 12px;
    color: #333333;
    align-items: center;
    text-wrap: nowrap;
    font-weight: 700;
    text-align: center !important;
} 

.table > tbody tr .amount{
    color: red;
}
.table > tbody tr .event-name{
    color: #466DE1 ;
}


@media screen and (max-width:768px) {
    
.open-bets-data-table-section{
    width: 100%;
    margin-top: 0px;
}
    
.open-bets-data-table-section.all-open-bets{
    margin-top: 70px;
}

.open-bets-data-table-actions-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 107px;
    background-color: #2947A2;
}
.table > thead tr th{
    font-size: 11px;
}

.table > tbody tr td{
    font-size: 10px;
} 
}

.open-bets-pagination{
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination-actions{
    width: 250px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}
.page-No{
    width: 25%;
    height: 100%;
    background-color: #000;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.prev-btn{
    width: 43%;
    height: 100%;
    outline: none;
}
.next-btn{
    width: 35%;
    height: 100%;
    
   
}
.btn{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    text-wrap: nowrap;
    outline: none;
    cursor: pointer;
}

.prev-btn > button,.next-btn > button{
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev-btn > button .prev-icon{
    width: 10px;
    height: 10px;
    margin-right: 3px;
    margin-top: 2px;
}
.next-btn > button .next-icon{
    width: 10px;
    height: 10px;
    margin-left: 3px;
    margin-top: 5px;
    transform: rotate(180deg);
}