:root{
   --match-details-card-bg:#000000;
   --team-name-color:#ffffff;
   --font-family: "Inter", sans-serif;
   --white-color:#ffffff;
   --chart-teams-navigator-btn:#2947A2;
}

.sports-market-section{
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
}
.sports-match-details-card{
    width: 100%;
   
}
.sports-match-details-chart-header{
    width: 98%;
    margin: auto;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.team-1{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 650;
    color: var(--team-name-color);
    font-family: var(--font-family);
}
.team-2{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 650;
    color: var(--team-name-color);
    font-family: var(--font-family);
}

.current-live-match-status{
    flex: 3;
    text-align: center;
}
.match-inning-detail{
  display: flex; 
  justify-content: center;
}
.inning{
    background-color: #1e1e1e;
    color: var(--white-color);
    text-align: center;
    padding: 2px;
   
}
.line{
    width: 2px;
    height: 21px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: var(--white-color);
    margin-top: 2px;
}

.over{
    background-color: #1e1e1e;
    color: var(--white-color);
    text-align: center;
    padding: 2px;
}

.yet-to-bet-detail{
    margin-top: 5px;
    font-size: 1.5rem;
    color: var(--white-color);
}
.yetobet-total-score{
    font-weight: 650;
}
.team-comparation-detail{
    margin-top: 5px;
    font-size: 0.9rem;
    padding-top: 3px;
    color: var(--white-color);
    font-family: var(--font-family);
}

.chart-teams-navigator-buttons{
    width: 95%;
    margin: auto;
    display: flex;
    padding-bottom: 20px;
}
.chart-first-team-navigator{
    width: 160px;
    height: 35px;
    border-radius: 8px;
}
.team-navigator-button{
    width: 100%;
    height: 100%;
    color: var(--white-color);
    background-color: transparent;
    border-radius: 8px; 
    border: none;
    font-size: 1rem;
    font-family: var(--font-family);
    cursor: pointer;
    transition: all ease  0.5s;
}
#active-team-btn{
    background-color: var(--chart-teams-navigator-btn);
}
.chart-second-team-navigator{
    width: 160px;
    height: 35px;
    border-radius: 8px;
    margin-left: 10px;
}

.sports-market-visualization-chart{
    margin-top: 0px;
}
