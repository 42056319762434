
.play-game-section{
    margin-top: 20px;
}


.play-games-container_{
    /* display:grid;
    grid-template-columns: repeat(4, 1fr);  
    gap: 5px; 
    justify-content: center;   */
      margin-top:15px !important;
}

.play-game-card{
    margin-left: 0px !important;
    cursor: pointer;
}
.play-game-card:nth-child(1){
  margin-left: 0px;
}
.play-game-img{
    width: 110%;
    height: 100%;

}


@media screen and (max-width:768px) {
    .play-game-section {
        margin-top: 10px;
    }

    .play-game-section-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .play-games-container_ {
        margin-top: 15px !important;
        padding-left: 0 !important;
    }


    .play-game-img {
        width: 125% !important;  
        height: 100% !important;
    }
    .play-game-img:nth-child(1) {
        margin-left: -15px !important; /* Explicitly remove margin from the first card */
    }
}


