:root{
    --sport-card-sport-name-color:#ffffff;
}

.exchange-inplay-sport-table {
    width: 100%;
    margin: auto;
    margin-top: 8px;
    background-color: transparent;
    padding: 10px 0px 0px 0px;
}


.exchange-inplay-sport-table-body {
    width: 100%;
    margin-top:5px;

}