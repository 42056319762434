.MuiTextField-root {
  padding-bottom: 5px !important;
}

.kycnew {
  margin-top: 1.5rem;
}

.kycsign {
  margin-top: 3.5rem;
}

.kycbody {
  height: 20rem;
}

.newsignup {
  display: flex;
  justify-content: center;
  padding-bottom: 0px;
}

.signbtkyc {
  padding: 3px 50px !important;
}

.popup-content1 {
  background-color: #fff;
  border-radius: 4px;
  max-width: 70%;
  width: 400px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: 20rem;
}
.popup-content11 {
  background-color: #fff;
  border-radius: 4px;
  max-width: 70%;
  width: 400px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: 13rem;
}

.labelotp1 {
  text-align: center;
  margin-bottom: 25px;
  background-color: #30469C;
  color: #fff;
}

.enterotp {
  font-size: 14px;
  line-height: 30px;
  display: block;
  text-align: left;
}

.otpinput {
  justify-content: center;
  margin-top: 1rem;
}

.otpinput input {
  margin-right: 5px !important;
  margin-left: 5px !important;
  width: 25px !important;
  height: 28px !important;
}

.valid {
  display: flex;
  justify-content: center;
}

.spnvalid {
  margin-top: 13px;
  color: red;
  font-size: 12px;
}

.otpverify {
  display: flex;
  justify-content: center;
  margin: 8px 0;
  margin-top: 15px;
}

.spnverify {
  font-size: 11px !important;
  display: block !important;
  line-height: 19px !important;
}

.expires {
  display: flex;
  justify-content: center;
  margin-top: 11px;
}

.spnexp {
  font-size: 12px;
}

.kycinput {
  margin: 0 30px;
}
.clrspan {
  color: #b264b9;
}
.clrspan1 {
  
    color: #b264b9;
    /* margin-right: 76px; */
    justify-content: center;
    display: flex;
    margin-top: 2px;

}

.kyc-hdr {
  padding: 5px 8px !important;
  background: #040f2e;
  color: #fff;
  font-family: "Roboto";
  font-family: "Poppins", sans-serif;
}

svg.cross-kyc {
  width: 20px;
  height: 20px;
}

.button-login{
  background: #D8E14F !important;
}

