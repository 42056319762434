@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Work+Sans:wght@100..900&display=swap');

:root {
    --inplay-sectin-header-color: #2947A2;
    --font-family: "Inter", sans-serif;
    --inplay-section-header-logo-text-color: #ffffff;
    --inplay-header-logo-bg-color: #D6DF3C;
    --white-color: #ffffff;
    --total-open-bets-box-color: #D6DF3C;
    --sports-card-sports-name: #333333;
    --comman-sports-table-border-color: #D9D9D9;
    --sport-score-details-color: #2947A2;
    --match-team-name-color: #333333;

}


.sport-table {
    width: 100%;
    margin-top: 10px;
}


.match-details-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--comman-sports-table-border-color);
    border-left: 0px;

}


.match-score-and-team-details-section {
    width: 53%;
    display: flex;
    justify-content: space-between;
}

.current-match-score-details {
    flex: 1;
    border: 1px solid var(--comman-sports-table-border-color);
    border-left: 0px;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 0px;
    border-top: 0px;
}

#football,
#tennis {
    flex: 1;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border: 1px solid var(--comman-sports-table-border-color);
    border-bottom: 0px;
    border-top: 0px;
    border-left: 0px;
}


.total-over {
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
    font-family: var(--font-family);
    padding-top: 0px;
    color: var(--sport-score-details-color);
}

.match-target {
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 3px;
    font-family: var(--font-family);
    color: var(--sport-score-details-color);
}

.current-team-score {
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 3px;
    font-family: var(--font-family);
    color: var(--sport-score-details-color);
}

@media screen and (max-width:768px) {

    .match-score-and-team-details-section {
        width: 53%;
        display: flex;
        justify-content: space-between;
    }

    .total-over {
        font-size: 10px;

    }

    .match-target {
        font-size: 10px;

    }

    .current-team-score {
        font-size: 10px;

    }


}

/* Football match score styling ---------------------- */

.left-score {
    font-size: 10px;
    font-weight: 400;
    color: var(--sport-score-details-color);
    font-family: var(--font-family);

}

.right-score .right-score1 {
    font-size: 10px;
    font-weight: 400;
    color: var(--sport-score-details-color);
    font-family: var(--font-family);
}

.right-score .right-score2 {
    font-size: 10px;
    font-weight: 400;
    color: var(--sport-score-details-color);
    font-family: var(--font-family);
}

/* end of football match styling ----------------------- */

/* upcoming match details styling ------------------ */
.upcoming-match-name {
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
    padding-top: 0px;
    margin-left: 8px;
}

.upcoming-match-date {
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--sport-score-details-color);
    text-wrap: wrap;
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;

}

/* upcoming match details styling ------------- */
@media screen and (max-width:768px) {

    /* upcoming match details styling ------------------ */
    .upcoming-match-name {
        font-size: 11px;
        line-height: 13px;
        font-weight: 600;
        font-family: var(--font-family);
        color: var(--match-team-name-color);
        padding-top: 0px;
        margin-left: 8px;
    }

    .upcoming-match-date {
        font-family: var(--font-family);
        font-size: 0.6rem;
        color: var(--sport-score-details-color);
        text-wrap: wrap;
        word-wrap: break-word;
        /* For legacy support */
        overflow-wrap: break-word;

    }

    /* upcoming match details styling ------------- */

}

.match-teams {
    flex: 3;
    height: 55px;
    max-height: 55px;
    padding-left: 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2px;
    padding-top: 0px;
    max-width: 80%;
}

.team-bet-link {
    text-decoration: none;
}

.teams .team1 {
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
    margin-left: 8px;
}

.teams .team2 {
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
    position: relative;
    margin-top: 4px;
    margin-left: 8px;
}

.teams .team2::before {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    background-color: var(--inplay-sectin-header-color);
    border-radius: 50%;
}

.live-match-icon-image {
    width: 20px;
    height: 17px;


}

.live-match-icon-image img {
    width: 100%;
    height: 100%;
    margin-top: -10px;
}

@media screen and (max-width:768px) {
    /* upcoming match details styling ------------- */




    .teams .team1 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 600;

    }

    .teams .team2 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 600;


    }

    .teams .team2::before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
    }



}





/* Bet boxes section --------------------------- */
.bets-boxes {
    width: 47%;
    display: flex;
    justify-content: space-between;
}

.back-details {
    flex: 1;
    max-height: 55px;
    background-color: #0F36AC;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.back-details.back2{
    background-color: #e85d4a;
}

.lay-details {
    flex: 1;
    max-height: 55px;
    background-color: #0F36AC;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 2px;
    margin-right: 2px;
    padding-bottom: 2px;

}
.lay-details.lay2{
    background-color: #e85d4a;
}

.lay1,
.back1 {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 14px;
    text-wrap: wrap;
    color: var(--white-color);
    font-weight: 600;
    text-wrap: wrap;
    box-sizing: border-box;

}


.lay1Totals,
.back1Totals {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 11px;
    text-wrap: wrap;
    color: var(--white-color);
    margin-top: 3px;
    box-sizing: border-box;

}



.bet-status {
    flex: 1;
    max-height: 55px;
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    background-color: #5091F1;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    font-size: 40px;
    color: var(--white-color);
    box-sizing: border-box;
    margin-right: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
}

.live-icon-image {
    width: 19px;
    height: 19px;
}

.live-icon-image img {
    width: 100%;
    height: 100%;
}



.cricket-first-player-bet-score {

    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 14px;
    text-wrap: wrap;
    color: var(--white-color);
    font-weight: 600;
    text-wrap: wrap;
    box-sizing: border-box;

}

.cricket-first-player-win-amount {
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 11px;
    text-wrap: wrap;
    color: var(--white-color);
    margin-top: 3px;
    box-sizing: border-box;

}

.cricket-first-player-bet-score,
.cricket-first-player-win-amount {
    max-width: 100%;

}


.cricket-second-player-bet-score {
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 14px;
    text-wrap: wrap;
    color: var(--white-color);
    font-weight: 600;
    box-sizing: border-box;

}

.cricket-second-player-win-amount {

    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 11px;
    text-wrap: wrap;
    color: var(--white-color);
    margin-top: 3px;
    box-sizing: border-box;
}

.cricket-second-player-bet-score,
.cricket-second-player-win-amount {
    max-width: 100%;
    overflow: auto;
}

.cricket-second-player-bet-score::-webkit-scrollbar-thumb,
.cricket-second-player-win-amount::-webkit-scrollbar-thumb {
    display: none;
}


@media screen and (max-width:768px) {

    /* Bet boxes section --------------------------- */
    .bets-boxes {
        width: 47%;
        display: flex;
        justify-content: space-between;
    }

    .back-details {
        flex: 1;
        max-height: 55px;
        background-color: #0F36AC;
        text-align: center;
        padding: 5px;

    }


    .lay-details {
        flex: 1;
        max-height: 55px;
        background-color: #0F36AC;
        padding: 5px;
        padding-top: 2px;
        padding-bottom: 2px;

    }

    .lay1,
    .back1 {
        font-size: 14px;
        text-wrap: wrap;
        color: var(--white-color);
        font-weight: 600;


    }


    .lay1Totals,
    .back1Totals {

        font-size: 11px;
        text-wrap: wrap;
        color: var(--white-color);


    }



    .bet-status {
        flex: 1;
        max-height: 55px;
        background-color: #5091F1;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 40px;
        margin-right: 2px;
    }



    .cricket-first-player-bet-score {
        font-size: 14px;
        text-wrap: wrap;
        color: var(--white-color);
        font-weight: 600;
    }

    .cricket-first-player-win-amount {
        font-size: 11px;
        text-wrap: wrap;
        color: var(--white-color);
        margin-top: 3px;
        box-sizing: border-box;

    }


    .cricket-second-player-bet-score {
        font-size: 14px;
        text-wrap: wrap;
        color: var(--white-color);
        font-weight: 600;
        box-sizing: border-box;

    }






}