.bet-head1 {
  color: #fff;
  position: relative;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  padding: 8px 19px 8px 8px;
  border-radius: 0;
  margin-bottom: 0;
  background: #436ad8;
}

.no-gutters {
  margin-top: 5rem;
}

.mar1 {
  background: #ddd;
}

.bet-cent li a {
  padding: 3px 19px;
  height: auto;
  line-height: normal;
  width: 100%;
  display: inline-block;
  margin: 0;
  font-size: 15px;
}

.tble {
  margin: 15px 0px;
}

.my-bet-tab2 thead {
  background: #54555a;
  color: #fff;
  font-size: 15px;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

.activeButton {
  background: green;
  color: #fff !important;
}

.bethis-card {
  display: flex;
  border-bottom: 1px solid #ddd;
  height: 32px;
}

.card-child {
  margin: -10px 10px;
}

.btn-gren {
  background: #00b2ff;
  color: #fff !important;
}

.profile-bet {
  width: 21px;
  margin-top: -6px;
}

.loss-bet {
  margin: -10px 0;
}

.content-bet {
  padding: 0 !important;
  height: 11.5rem !important;
}

.amount-bethis {
  float: right;
  margin: -41px 10px;
}
.exchange-tab{
  background-color: red !important;
}
.date-bethis {
  margin: -10px 16px;
}

.text-bethis {
  font-size: 16px;
  font-family: "Roboto";
}

.text-bethis1 {
  font-size: 11.5px;
  font-family: "Roboto";
  color: #90969b;
}

.match-bethis {
  margin: -25px 15px;
}

.team-bethis {
  font-size: 12px;
  font-weight: 500;
}

.profilepg {
  margin: 15px 15px;
  line-height: 50px;
  padding-top: 15px;
}

.tab-bet {
  background: #040f2e !important;
  color: #fff !important;
  width: 40% !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.tab-bet-ac {
  background: #040f2e !important;
  color: #fff !important;
  width: 30% !important;
  font-family: "Roboto Slab" !important;
  font-size: 14px !important;
}

.css-19kzrtu {
  padding: 4px 0 !important;
}

.MuiTabs-indicator {
  background-color: #040f2e !important;
}

.bg-tab-bet {
  background: #040f2e !important;
}

.MuiTabs-scroller {
  margin-bottom: 2px !important;
}

.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-around !important;
}

.filter-bet {
  width: 25px;
  height: 20px;
  float: right;
  margin: 4px 5px;
}

.popup3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content3 {
  background-color: #fff;
  border-radius: 4px;
  max-width: 95%;
  width: 400px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: 13.5rem;
  position: fixed;
  top: 50px;
  right: 10px
}

.bet-r {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.signbt4 {
  padding: 3px 25px !important;
  float: right !important;
  margin: 10px 5px !important;
}

.label-bet {
  font-family: "Roboto Slab";
  font-size: 10px;
  margin-bottom: 2px;
}

.inpt-bet {
  font-size: 15px;
  align-items: end;
}

.betimage {
  width: 45px;
}

.nodata {
  margin-top: -25px;
  font-size: 12px;
}

@media (min-width: 1200px) {
  .popup-content3 {
    background-color: #fff;
    border-radius: 4px;
    max-width: 95%;
    width: 400px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    height: 13.5rem;
    position: fixed;
    top: 50px;
    right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  form {
    padding: 0 20px !important;
  }

  label {
    max-width: 100%;
  }

  .input-search {
    width: 100%;
  }
}

.pending-bets {
  margin-bottom: 10px;
  margin-top: 90px;
}