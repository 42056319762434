.depositbutton {
  float: right;
}

.radio-grp {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  flex-wrap: nowrap !important;
}

.btn-manual {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px;
}

.bg-none {
  background: none !important;
  display: flex !important;
}

.back {
  background: none !important;
}

.copy {
  display: flex;
  justify-content: space-between;
}

.depo-info {
  margin: 10px !important;
}

.card-bg {
  background: #dddddd75 !important;
}

.white-bg {
  background: #ffff;
  padding-bottom: 0 !important;
}

.btn-align {
  gap: 10px;
  display: flex;
  justify-content: center;
}

.btn-utr {
  background: #30469C !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.amount {
  margin-bottom: 4rem !important;
}

.submit-btn {
  background: #091F58 !important;
  color: #fff !important;
}

.pdeposit {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: black !important;
  margin-left: 6px !important;
}

.depo-mobile {
  color: #fff;
  padding: 0px 8px;
  font-family: "Roboto Slab";
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}

.image-deposit {
  width: 45px;
}

.crosssvg {
  float: right;
  margin-right: 10px;
  margin-top: -2px;
}

.allimg {
  display: flex;
  justify-content: space-evenly;
}

.padtop {
  padding-top: 12px;
}

.white-bg {
  background: #fff;
}

.tpDV1 {
  background: #30469C;
  height: 90px;
}

.tpDV1 p {
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #636363;
}

.popup-content2 {
  background-color: #fff;
  border-radius: 4px;
  max-width: 70%;
  width: 400px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: 15rem;
}

.labelotp2 {
  text-align: center;
  margin-bottom: 25px;
  background-color: #040f2e;
  color: #fff;
}

.enterotp2 {
  font-size: 14px;
  line-height: 30px;
  display: block;
}

.crosssvg2 {
  float: right;
  margin: -1px 8px;
  cursor: pointer;
}

.inptdepo {
  padding-bottom: 10px !important;
}

.signbt2 {
  padding: 3px 25px !important;
}

.signbt3 {
  background: #D8E150 !important;
  border-radius: 0 !important;
  padding: 3px 25px !important;
}

.cardcont {
  padding: 3px !important;
}

.floatdepo {
  float: right;
  margin: 20px 20px 0 20px;
}

.potion {
  margin: 3px;
}

.potion-money {
  color: black;
}

.depo-money {
  padding: 0px 8px !important;
  background-color: #30469C !important;
  border-color: #30469C !important;
  font-size: 13px !important;
  width: 45px !important;
  border-radius: 0 !important;
}

.depo-money:hover {
  background: #D8E150 !important;
  border: none;
  color: #30469C !important;
}

.potion1:hover {
  border: 3px solid #30469C !important;
}

.depo-username {
  color: #fff;
  padding: 8px 8px !important;
  font-family: "Roboto Slab";
  font-size: 15px;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -5px;
}

.MobileFooter_mobileFooter1 {
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 45px;
  left: 0;
  margin-left: 0;
  position: fixed;
  transition: bottom 0.2s ease-in-out, background 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
  border-top: 1px solid #ddd;
}

.MobileFooter_footerItem1 {
  flex: 1 1;
  max-height: 100%;
  opacity: 0.78;
  position: relative;
  display: flex;
  align-items: center;
}

.MobileFooter_itemContent1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  text-align: center;
  text-decoration: none;
}

.MobileFooter_label1 {
  color: #fff;
  font-size: 13px;
  text-align: center;
  padding-left: 22px;
  font-family: Roboto Slab;
}

.depo-foot {
  border-top: 1px solid #ddd;
}

.depo-head {
  height: 49px;
}

.MobileFooter_icon {
  height: 26px;
  margin: 6px auto 3px;
  object-fit: contain;
  cursor: pointer;
  width: 30px;
}

.error-throw {
  text-align: center;
  font-size: 10px;
  color: red;
}

.spinner-loader {
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

.spinner-square {
  display: flex;
  flex-direction: row;
  width: 50px;
  height: 120px;
}

.spinner-square>.square {
  width: 10px;
  height: 80px;
  margin: auto auto;
  border-radius: 4px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
}

@keyframes square-anim {
  0% {
    height: 20px;
    background-color: rgb(111, 163, 240);
  }

  20% {
    height: 20px;
  }

  40% {
    height: 40px;
    background-color: rgb(111, 200, 240);
  }

  80% {
    height: 20px;
  }

  100% {
    height: 20px;
    background-color: rgb(111, 163, 240);
  }
}

@media screen and (max-width: 1200px) {
  .rupee {
    float: right;
    margin-right: 15px;
    margin-top: -27px;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .popup-content2 {
    height: 15rem;
  }

  .potion img {
    width: 70px;
    max-width: 90px;
  }

  .inptdepo {
    width: 320px;
  }
}