.top-games-slider-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-games-slider-show-more-button {
    padding: 5px;
    border: 1px solid #30469C;
    cursor: pointer;
    border-radius: 20px;
}

.top-games-slider-header-text {
    margin-bottom: 0;
    color: #30469C;
    font-size: 16px;
    font-weight: bold;
}

.top-games-slider-button-text {
    margin-bottom: 0;
    color: #30469C;
    font-size: 14px;
    font-weight: '600';
}

.top-games-slider-main-container {
    margin: 0 40px;
}

.top-games-slider-main-container-mobile {
    margin: 0 20px;
}