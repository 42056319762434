.popup-content-language {
    background-color: #fff;
    border-radius: 4px;
    max-width: 70%;
    width: 400px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    height: 9rem;
}

.enterotp-language {
    font-size: 14px;
    line-height: 30px;
    display: block;
    text-align: left;
    margin-left: 12px;
}