.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure it appears on top of other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #fff;
  border-radius: 4px;
  /* Additional styles for responsiveness */
  max-width: 70%;
  width: 400px; /* Adjust the width as needed */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: 13rem;
}

.otpinput {
  justify-content: center;
  margin-top: 2.5rem;
}

.labelotp {
  text-align: center;
  margin: 15px 0;
}

.labelotp1 {
  text-align: center;
  margin-bottom: 25px;
  background-color: black;
  color: #fff;
}

.otpverify {
  display: flex;
  justify-content: center;
  margin: 8px 0;
  margin-top: 15px;
}

.otpinput input {
  margin-right: 5px !important;
  margin-left: 5px !important;
  width: 25px !important;
  height: 28px !important;
}

.spnverify {
  font-size: 11px !important;
  display: block !important;
  line-height: 19px !important;
  
}

.btnverify {
  padding: 0 15px !important;
  height: 22px !important;
  display: block !important;
}

.enterotp {
    font-size: 14px;
    line-height: 30px;
    display: block;
}
.expires {
    display: flex;
    justify-content: center;
    margin-top: 11px;
}

.spnexp {
    font-size: 12px;
}

.clrspan {
    color: #b264b9;
}
.valid {
    display: flex;
    justify-content: center;
}

.spnvalid {
    margin-top: 13px;
    color: red;
    font-size: 12px;
}