

.top-providers-section{
    width: 100%;
    margin-top: 10px;
}
.top-providers-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.swiper-container{
    margin-top:15px !important;
}

#provider-swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #31469C;
    -webkit-appearance: none;
    border-radius: 0;
    margin-top: 20px;
}


#provider-swiper .swiper-pagination-bullet-inactive {
    opacity: 1;
    background-color: #333;
    -webkit-appearance: none;
    border-radius: 0;
}



#provider-swiper .swiper-pagination-bullet {
    display: inline-block;
    height: 2px;
    width: 20px;
    -webkit-appearance: none;
}

#provider-swiper  .swiper-pagination {
    position: relative;
    margin-top: 5px;
}

.top-provider-image{
    
        width: 95%;
        margin-inline-end: 10px;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 7px;
}


@media screen and (max-width:600px) {
    .top-provider-image{
    
        width: 95%;
        margin-inline-end: 10px;
        margin-top: 10px;
        cursor: pointer;
}

.swiper-container{
    margin-top:10px !important;
}
}