.outer-bg {
  
    background: #333333;
    margin-top: 60px;
}
.outer-cont {
    max-width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    
}
.match-teams-details{
    display: flex !important;
   
}


.play-spn {
    color: #fff;
    margin-left: 10px;
}

.spn-play {
    font-size: 13px;
}

.svg-btn {
    margin-top: 10px;
    width: 35px;
    height: 28px;
}

.svg-info {
    float: right;
}


@media screen and (max-width:768px) {
    .outer-bg {
        background: #333333;
        margin-top: 60px;
    }
    .outer-cont {
        max-width: 98%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        
    }
   
    

 
}