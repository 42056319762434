.top-games-section {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
}

.top-game-card {
    max-width: 130px;
    margin-bottom: 14px;
    margin: 0px 6px 0px 6px;
    margin-top: 20px;
    cursor: pointer;
}

.top-games-container {
    margin-top: 28px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
}

.game-image {
    width: 130px;
    height: 163px;
    border-radius: 12px;

}

.game-image img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.game-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2px;
    padding-top: 4px;
}

.gameName {
    max-width: 100%;
    font-size: 13px;
    color: #231E28;
    margin-top: 1px;
    font-weight: 500;
    line-height: 10px;
    word-break: break-all;
    line-height: 0.9rem;
   
}

.game-sub-title {
    font-size: 11px;
    color: #31469C;
    margin-top: 5px;
    font-weight: 500;
    line-height: 10px;
}

.playIcon {
    width: 18px;
    height: 18px;
    margin-left: -6px;
}

.gameLeftDetail {
    font-family: "Poppins", sans-serif;
}



@media screen and (max-width:600px) {

    .top-games-container {
        margin-top: 25px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8;
        justify-items: center;
        padding-left: 0px;
    }

    .top-game-card {

        margin: 0px;
        margin-bottom: 14px;
    }


    .game-image {
        width: 115px;
        height: 153px;
        border-radius: 12px;

    }

    .game-image img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }

    .game-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 3px;
        padding-top: 4px;
    }

    .gameName {
        font-size: 10px;

        margin-top: 1px;
        font-weight: 500;
        line-height: 10px;
    }

    .game-sub-title {
        font-size: 7px;

        margin-top: 2px;
        font-weight: 500;
        line-height: 10px;
    }


}


@media screen and (min-width:340px) and (max-width:370px) {

    .top-games-container {
        margin-top: 14px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8;
        justify-items: center;
    }

    .game-image {
        width: 105px;
        height: 143px;
        border-radius: 12px;

    }
}