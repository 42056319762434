.bet-history-filter-modal-pop-up-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.withdrawal-popup-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
}

.bet-history-filter-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
}

.bet-history-filter-modal-input-select {
    width: 45px;
}

.bet-history-filter-modal-pop-up-button-container {
    margin-top: 3px;
    margin-right: 15px;
}

.bonus-wallet-modal-container {
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bonus-wallet-text {
    color: #30469C;
    font-family: "Roboto Slab";
    margin: 0 5px 15px 5px;
    margin-bottom: 0;
}

.bonus-wallet-text-alt {
    color: #D8E150;
    font-size: 11px;
    font-family: "Roboto Slab";
    margin: 0 5px 15px 5px;
    margin-bottom: 0;
}

.bonus-wallet-modal-container-footer {
    background-color: #30469C;
    display: flex;
    align-self: flex-end;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 4px 8px;
}

.upi-id{
    margin-top: 25px;
}

.button-login{
    background: #D8E14F !important;
  }