.high-games-casino-container{
    width: 100%;
}
.high-earning-games-section{
    margin-top: 30px;
}
.sub-container-games{
    display: flex !important;
   flex-wrap: nowrap !important;
}
.indian-casino-inner-container{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 0px;
}
.casino-container{
    width: 99%;
    margin: auto;
    margin-top: 28px;
}
