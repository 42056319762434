
.games-header-marker{
    width: 97%;
    margin: auto;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.marker{
    padding-left: 10px;
    max-width: 240px;
    background: linear-gradient(#31469C,#0B1D67);/* Blue background */
    border: none;
    color: #FFFFFF;
    padding: 5px 35px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    outline: none;
    clip-path: polygon(0 0, 90% 0, 100% 0%, 80% 100%, 0 100%);
    transition: all 0.3s ease;
    font-weight: 500;
    margin-right: 0px !important;
}
.marker span {
    margin-left: -2rem;
}
.view-all-btn.desktop{
    margin-right: 20px;
}

.view-all-btn button{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    border: 1px solid #31469C;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 20px;
}

.viewall-svg{
    width: 20px;
    height: 20px;
}

.view-all-btn.desktop > button{
    font-size: 14px;
}

.view-all-btn{
    width: 90px;
    height: 25px;
    border-radius: 16px;
}
.view-all-button .link{
    text-decoration: none;
    color: #31469C;
   
}

@media screen and (max-width:440px) {
    .marker span {
        margin-left: -1.5rem;
    }
}
@media screen and (max-width:400px) {
    .marker span {
        margin-left: -1.5rem;
    }
}
@media screen and (max-width:768px) {
    .games-header-marker{
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    }
    .view-all-btn{
        margin-right: 10px;
    }
    .marker{
        max-width:220px;
        padding: 5px 30px;
       
    }
        
}
