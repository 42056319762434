
.MobileFooter-outer{
    background-color:#091F58 ;
}

.MobileFooter_mobileFooter {
    background-color: #091F58;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 55px;
    left: 0;
    margin-left: 0;
    position: fixed;
    transition: bottom 0.2s ease-in-out, background 0.2s ease-in-out;
    width: 100%;
    z-index: 10;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  
  .MobileFooter_footerItem {
    flex: 1;
    max-height: 100%;
    opacity: 0.78;
    position: relative;
  }
  
  .MobileFooter_itemContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    text-align: center;
    text-decoration: none;
  }
  
  .MobileFooter_icon {
    height: 30px;
    margin: 3px auto 3px;
    object-fit: contain;
    cursor: pointer;
    width: 30px;
  }
  
  .MobileFooter_label {
    color: #D9D9D9;
    font-size: 12px;
    text-align: center;
    /* font-weight: 500; */
    font-family:var(--font-family);
    font-weight: 300;
  }
  